$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  border: #{toRem(1) solid var(--b-color-fill-neutral-02)};
  border-radius: #{toRem(8)};
  width: 100%;
  min-width: 0;
  padding: #{toRem(8)} #{toRem(12)};

  background-color: unset;

  text-align: start;

  &:hover {
    cursor: pointer;
  }
}

.name {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.contentWrapper {
  display: flex;
  gap: var(--b-space-xxs);
  flex-direction: column;
}

.withScroll {
  margin-right: var(--b-space-m);
  width: 40%;

  @media #{$fromLaptop} {
    width: #{toRem(350)};
  }

  [dir='rtl'] & {
    margin-left: var(--b-space-m);
    margin-right: unset;
  }
}

.ellipsisText {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
