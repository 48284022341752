$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/images';

.container {
  overflow: hidden;

  box-shadow: var(--b-shadow-hard);
  border-radius: var(--b-space-m);
  padding: var(--b-space-l) var(--b-space-xl) ;

  background-color: var(--b-color-bg-white);
  background-size: cover;
  background-position: center center;

  &.notFound {
    @include bestQualityBackgroundImage('GeneralS', 358, 358);

    @media #{$fromTablet} {
      @include bestQualityBackgroundImage('GeneralL-M', 896, 896);
    }
  }

  &.essential {
    @include bestQualityBackgroundImage('EssentialS', 358, 358);

    @media #{$fromTablet} {
      @include bestQualityBackgroundImage('EssentialL-M', 896, 896);
    }
  }

  &.special {
    @include bestQualityBackgroundImage('SpecialS', 358, 358);

    @media #{$fromTablet} {
      @include bestQualityBackgroundImage('SpecialL-M', 896, 896);
    }
  }

  &.unique {
    @include bestQualityBackgroundImage('UniqueS', 358, 358);

    @media #{$fromTablet} {
      @include bestQualityBackgroundImage('UniqueL-M', 896, 896);
    }
  }

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}

.content {
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}

