$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

$hoziontalContainerSpacing: var(--b-space-m);

.header {
  padding: var(--b-space-l);

  background-color: var(--b-color-bg-light);
  @media #{$onlyMobile} {
    padding: var(--b-space-l) $hoziontalContainerSpacing;
  }
}

.headerContentWrapper {
  @media #{$onlyMobile} {
    flex-direction: column;
  }
}

.ratingDetailsWrapper {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;

  width: 100%;

  @media #{$fromTablet} {
    max-width: toRem(350);
  }
}

.reviewsWrapper {
  padding: var(--b-space-l) $hoziontalContainerSpacing;
}
