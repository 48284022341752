$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.wrapper {
  position: relative;

  height: 100%;
}

.swiperSlideWithoutNavigationArrows {
  gap: var(--b-space-m);
}

.swiperContainer {
  width: 100%;
}

.imageWrapper {
  display: flex;

  height: toRem(260); // Número sacado de cómo está montando en el CMS
}

.image {
  align-self: center;

  border-radius: toRem(8);
  width: auto;
  height: auto;
  max-height: toRem(260); // Número sacado de cómo está montando en el CMS

  object-fit: cover;

  background-color: var(--b-color-fill-neutral-02);
}

.descriptionText {
  height: fit-content;
}

.arrowContainer {
  position: absolute;
  z-index: var(--b-z-index-2);
  top: calc(toRem(260) + var(--b-space-m));

  display: flex;
  gap: var(--b-space-l);
  align-self: flex-end;
}
