$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/fonts';

.hotelCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  box-shadow: var(--b-shadow-soft);
  border: toRem(1) solid var(--b-color-fill-neutral-02);
  border-radius: toRem(8);
  max-width: toRem(283);
  min-width: toRem(283);

  background-color: var(--b-color-bg-white);

  @media #{$fromTablet} {
    max-width: toRem(382);
    min-width: toRem(382);
  }

  &:last-child {
    margin-right: toRem(10);
  }
}

.hotelImageContainer {
  position: relative;

  flex: 1;

  min-height: toRem(200);
}

.hotelImage {
  border-radius: toRem(8) toRem(8) 0 0;

  object-fit: fill;
}

.hotelInfo {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: column;
  flex: 2;
  overflow: hidden;

  margin: var(--b-space-l) 0;
  padding: 0 var(--b-space-m);
}

.hotelName {
  display: block;
  overflow: hidden;

  width: 100%;

  text-overflow: ellipsis;

  @include font-l-700;
}
