$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/common';

.container {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: row;
  align-items: center;

  margin-bottom: var(--b-space-m);
  margin-right: auto;
  border-radius: #{toRem(8)};
  padding: var(--b-space-xxs) var(--b-space-xs);

  background-color: var(--b-color-support-error-light);
  color: var(--b-color-text-accelerator-red);

  @media #{$onlyMobileAndTablet} {
    [dir='rtl'] & {
      margin-left: auto;
      margin-right: unset;
    }
  }
  @media #{$fromLaptop} {
    flex-shrink: 0;
    align-self: flex-start;

    margin-top: var(--b-space-xxs);
    margin-bottom: 0;
    margin-right: 0;
  }
}

.viewingNow {
  background-color: var(--b-color-tag-accelerator);
  color: var(--b-color-text-dark);
}
