.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.shrink-0  {
  flex-shrink: 0;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-xs {
  gap: var(--b-space-xs);
}
