$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/touch';

.hotelWrapper {
  display: flex;
  gap: var(--b-space-m);
  align-items: center;
  overflow: hidden;

  border: toRem(1) solid var(--b-color-divider);
  border-radius: var(--b-border-radius-8);
  width: 100%;

  transition: border-color 0.3s;
  cursor: pointer;
  &:hover {
    border-color: var(--b-color-fill-neutral-04);
  }

  @media #{$fromLaptop} {
    flex-shrink: 0;

    width: 50%;
  }
}

.heroDataWrapper {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;
  align-items: center;

  @media #{$fromLaptop} {
    gap: var(--b-space-l);
    flex-direction: row;
  }
}

.noDatesIcon {
  margin-right: var(--b-space-xs);
}

.openIcon {
  border-radius: var(--b-border-radius-50-percent);
  padding: var(--b-space-xxs);

  background-color: var(--b-color-fill-neutral-01);
}

.heroLineDataWrapper {
  display: flex;
  align-items: center;

  width: 100%;
}

.heroLineData {
  display: inline-flex;
  align-items: center;
  flex-grow: 1;

  margin-right: var(--b-space-xs);
  max-width: 100%;
}

.heroLineEllipsisText {
  @include font-s-500;
  flex-grow: 1;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.couponLink {
  @include interactiveUnderline;
  cursor: pointer;
}

.editButton {
  @include touch-security-zone-always;
  flex-shrink: 0;
}

.datesRange {
  text-transform: capitalize;
}
