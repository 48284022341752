$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.container {
  position: relative;

  border-radius: #{toRem(8)};

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border: #{toRem(1)} solid var(--b-color-fill-neutral-02);
    border-radius: #{toRem(8)};

    pointer-events: none;
    transition: 0.2s;
  }

  &.isSelected {
    &:before {
      border: #{toRem(2)} solid var(--b-color-interactive-primary-enabled);
    }
  }

  &:hover {
    background-color: var(--b-color-fill-neutral-01);
  }

  &.isDisabled {
    filter: opacity(40%);
  }
}

.label {
  display: block;

  padding: var(--b-space-m) var(--b-space-xs) var(--b-space-xs)
    var(--b-space-xs);

  cursor: pointer;
}

.inputRadio {
  position: absolute;
  top: #{toRem(16)};
  left: #{toRem(8)};

  [dir='rtl'] & {
    right: #{toRem(8)};
    left: unset;
  }

  width: var(--b-space-l);
  height: var(--b-space-l);

  cursor: pointer;
  accent-color: var(--b-color-interactive-primary-enabled);
}

.withTag {
  margin-top: var(--b-space-s);
}
