$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  position: relative;

}

.carousel {
  @media #{$fromLaptop} {
    border-radius: 0;
  }

  img {
    max-height: toRem(287);
    min-height: toRem(287);
  }
}

.numberWrapper {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;

  // tamaño definido en FIGMA
  width: toRem(60);
  height: toRem(60);
  padding: toRem(14) toRem(10); //padding definido en FIGMA

  background-color: var(--b-color-fill-neutral-02);

  pointer-events: none;
  user-select: none
}
