$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  background-color: var(--b-color-bg-light);
}

.mapImageContainer {
  position: relative;

  flex-grow: 1;
  overflow: hidden;
}

.mapImage {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-75%, -50%);

  width: auto;
  max-width: unset;
  height: 100%;
  min-height: toRem(800);
}

.map {
  flex-grow: 1;
}

.poiSlider {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;

  padding: var(--b-space-l) var(--b-space-m);

  background-color: var(--b-color-bg-white);

  @media #{$onlyMobileAndTablet} {
    border-top-left-radius: toRem(24);
    border-top-right-radius: toRem(24);
  }

  @media #{$fromLaptop} {
    gap: var(--b-space-l);

    padding: var(--b-space-m) var(--b-space-xl) var(--b-space-l)
      var(--b-space-xl);
  }
}

.showMoreIcon {
  @include touch-security-zone-always;

  align-self: center;
}
