$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.container {
  padding: var(--b-space-2xl) var(--b-space-m);

  @media #{$fromTablet}{
    padding: var(--b-space-2xl) var(--b-space-3xl) 0;
  }
}

.tabs {
  justify-content: center;

  margin-top: var(--b-space-l);
}

.advantageTab {
  margin-top: var(--b-space-m);

  @media #{$fromTablet} {
    margin-left: var(--b-space-3xl);
    margin-right: var(--b-space-3xl);
  }

  header {
    border-radius: var(--b-space-m);
    padding: var(--b-space-xl) var(--b-space-m);

    background-size: cover;
    background-position: center;

    text-align: center;
  }

  .benefitTitle {
    display: flex;
    gap: var(--b-space-xs);

    padding: var(--b-space-l) var(--b-space-m) var(--b-space-m) 0;
  }

  .divider {
    height: toRem(3);
  }

  .benefits {
    li {
      display: flex;
      gap: var(--b-space-xs);
      justify-content: space-between;

      padding: var(--b-space-m) 0;
    }
  }
}

.tooltipContent {
  max-width: toRem(540);
}

.essentialTab > header{
  background-image: url('https://s7g10.scene7.com/is/image/barcelo/EssentialS?fmt=png8-alpha');
}

.specialTab > header {
  background-image: url('https://s7g10.scene7.com/is/image/barcelo/SpecialS?fmt=png8-alpha');
}

.uniqueTab > header {
  background-image: url('https://s7g10.scene7.com/is/image/barcelo/UniqueS?fmt=png8-alpha');
}
