$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';

.container {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;

  margin-bottom: var(--b-space-xs);
  padding-left: var(--b-grid-mobile-lateral-gap);

  @media #{$onlyTablet} {
    padding-left: var(--b-grid-tablet-lateral-gap);
  }

  [dir='rtl'] & {
    padding-right: var(--b-grid-mobile-lateral-gap);

    @media #{$onlyTablet} {
      padding-right: var(--b-grid-tablet-lateral-gap);
    }
  }

  @media #{$fromLaptop} {
    padding-left: unset;
    padding-right: unset;

    [dir='rtl'] & {
      padding-left: unset;
      padding-right: unset;
    }
  }
}
