$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.list {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: column;
  overflow: hidden;
}

.promotion {
  position: relative;

  &.withEllipsis {
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.icon {
  margin-top: toRem(5);
}
