$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.stepsContainer {
  padding: var(--b-space-2xl) var(--b-space-m);

  background-color: var(--b-color-fill-neutral-01);

  @media #{$fromTablet} {
    padding: var(--b-space-4xl) var(--b-space-m);
  }
}

.steps {
  margin-top: var(--b-space-l);

  @media #{$fromTablet} {
    margin-top: var(--b-space-4xl);
  }
}

@mixin step() {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  border-radius: var(--b-space-l);
  width: 100%;
  padding: var(--b-space-xl) var(--b-space-xl);

  text-align: center;

  @media #{$fromTablet} {
    gap: var(--b-space-l);
    flex-direction: row;
  }
}

.step1 {
  @include step();
  background-color: var(--b-color-fill-pool-10);
}

.step2 {
  @include step();
  background: var(--b-color-fill-pool-100-gradient);
  color: var(--b-color-text-light);

  @media #{$fromTablet} {
    flex-direction: row-reverse;
  }
}

.step3 {
  @include step();
  background-color: var(--b-color-fill-neutral-04);
  color: var(--b-color-text-light);
}


.stepImageContainer {
  width: 100%;
  padding: 0 var(--b-space-2xl);

  @media #{$fromTablet} {
    width: 45%;
    height: toRem(142);
    padding: unset;
  }
}

.stepImage {
  width: 100%;
  min-width: toRem(99);

  object-fit: contain;

  @media #{$onlyMobile}{
    height: toRem(99);
  }
}

.stepContent {
  @media #{$fromTablet} {
    width: 65%;

    text-align: justify;
  }
}

.stepTitle {
  margin-top: var(--b-space-m);

  @media #{$fromTablet} {
    margin-top: unset;

    text-align: start;
  }
}

.stepDescription {
  margin-top: var(--b-space-m);

  @media #{$fromTablet}{
    text-align: start;
  }
}

