.w-full {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-7\/12 {
  width: 58.333333%;
}

.h-full {
  height: 100%;
}
