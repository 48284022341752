$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.list {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;

  @media #{$fromLaptop} {
    margin: 0 auto;
    max-width: #{toRem(400)};
  }
}
