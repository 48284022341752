@import 'src/ui/styles/tools';

@mixin interactiveUnderline {
  position: relative;

  display: inline-block;

  border-bottom: #{toRem(2)} solid var(--b-color-interactive-secondary-enabled);

  color: var(--b-color-text-dark);

  text-decoration: none;
}

@mixin visuallyHidden {
  position: absolute;

  overflow: hidden;

  width: toRem(1);
  height: toRem(1);

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

@mixin genericLink {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--b-color-interactive-secondary-hover);
  }

  &:active {
    color: var(--b-color-interactive-secondary-pressed);
  }
}

@mixin gradientStyles($position, $gradient-direction) {
  $webkit-direction: $gradient-direction - 90deg;

  content: '';
  position: absolute;
  z-index: var(--b-z-index-2);
  #{$position}: 0;
  width: var(--b-grid-mobile-lateral-gap);
  height: 100%;

  // -webkit-linear-gradient va de left to right y el linear-gradient va de bottom to top y la dirección
  // en linear-gradient va en clockwise y el -webkit-linear-gradient va en anti-clockwise
  background-image: linear-gradient(
    $gradient-direction,
    transparent 0%,
    var(--b-color-bg-white) 100%
  );
  background-image: -webkit-linear-gradient(
    $webkit-direction,
    rgba(255, 255, 255, 0) 0%,
    var(--b-color-bg-white) 100%
  );
  @media #{$fromLaptop} {
    content: unset;
  }
}

@mixin lateralScrollGradientOnMobile {
  [dir='ltr'] &::before {
    @include gradientStyles(left, 270deg);
  }

  [dir='ltr'] &::after {
    @include gradientStyles(right, 90deg);
  }

  [dir='rtl'] &::before {
    @include gradientStyles(right, 90deg);
  }

  [dir='rtl'] &::after {
    @include gradientStyles(left, 270deg);
  }
}

@mixin stickyButtonsWrapper {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  padding-top: var(--b-space-l);

  background-color: var(--b-color-bg-white);
}

@mixin hideOnNonTactileDevices {
  @media not all and (hover: none) and (pointer: coarse) {
    display: none;
  }
}

@mixin personalizedModalWebkitScrollBar($scrollOffset: 0px) {
  &::-webkit-scrollbar {
    width: toRem(6);
    height: toRem(6);
  }
  &::-webkit-scrollbar-track {
    margin-left: unset;
    margin-right: #{$scrollOffset};
    border-radius: toRem(6);

    background: var(--b-color-scrollbar-thumb);

    [dir='rtl'] & {
      margin-left: #{$scrollOffset};
      margin-right: unset;
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: toRem(6);

    background-color: var(--b-color-scrollbar-track);
  }
  /* Para Firefox */
  @-moz-document url-prefix() {
    & {
      scrollbar-width: thin;
      scrollbar-color: var(--b-color-scrollbar-track)
        var(--b-color-scrollbar-thumb);
    }
  }
}
