$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.swiperContainer {
  width: 100%;

  :global(.swiper-wrapper) {
    align-items: center;

    height: toRem(300); // Número sacado de cómo está montando en el CMS
  }
}

.swiperSlide {
  height: unset;
}

.image {
  flex-shrink: 0;

  border-radius: toRem(8);
  width: auto;
  height: auto;
  max-height: toRem(300); // Número sacado de cómo está montando en el CMS

  object-fit: cover;

  background-color: var(--b-color-fill-neutral-02);
}

.descriptionText {
  align-self: center;
}
