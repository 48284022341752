$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.dot {
  width: #{toRem(2)};
  height: #{toRem(2)};

  background-color: var(--b-color-icon-dark);
}

.averageName {
  @media #{$onlyMobile} {
    display: none;
  }
}
