$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mixins/common';

.container {
  @include fullWidthContainerLateralPadding;

  background-color: var(--b-color-bg-white);

  @media #{$fromLaptop} {
    box-shadow: var(--b-shadow-hard);
    border-radius: toRem(16);
    padding: var(--b-space-xl);
  }

  @media #{$onlyMobileAndTablet} {
    @include fullWidthContainer;
    padding: 0 0 var(--b-space-xl) 0;
  }

  &.isMobileDark {
    @media #{$onlyMobileAndTablet} {
      background-color: var(--b-color-bg-light);
    }
  }
}
.header {
  padding: var(--b-space-l) var(--b-space-m);

  background-color: var(--b-color-bg-light);

  @media #{$onlyTablet} {
    padding: var(--b-space-l);
  }

  @media #{$fromLaptop} {
    padding: unset;

    background-color: var(--b-color-bg-white);
  }
}

.nearbyHotels {
  @media #{$onlyMobileAndTablet} {
    margin-top: var(--b-space-l);
    padding-left: var(--b-space-m);

    [dir='rtl'] & {
      padding-left: unset;
      padding-right: var(--b-space-m);
    }
  }
}

.listItem {
  &:before {
    content: '\2022';

    display: inline-block;

    width: #{toRem(16)};

    color: var(--b-color-fill-ocean-15);
    font-weight: bold;
  }
}

.link {
  text-decoration-color: var(--b-color-text-dark);
}

.refreshButton {
  @include font-link;
  color: var(--b-color-text-dark);
}

.refreshButtonText {
  @include genericLink;
}
