$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.button {
  @include font-button;

  position: relative;

  border-radius: #{toRem(100)};
  width: 100%;
  padding: var(--b-space-xs) var(--b-space-xl);

  background: var(--b-color-button-primary-enabled);
  color: var(--b-color-icon-light);

  text-align: center;

  &:hover:enabled {
    background: var(--b-color-button-primary-hover);
    cursor: pointer;
  }

  &:active:enabled {
    background: var(--b-color-button-primary-pressed);
  }

  @media #{$onlyLaptop} {
    padding: var(--b-space-xs) var(--b-space-xl);
  }
}

.contentLoading {
  visibility: hidden;
}

.loaderWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
