$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.staysSummaryRoot {
  position: sticky;
  z-index: var(--b-z-index-3);
  bottom: 0;

  @media #{$fromLaptop} {
    z-index: var(--b-z-index-1);
  }
}

.marketingAlert {
  position: absolute;
  z-index: var(--b-z-index-3);
  top: 0;

  width: 100%;
  max-height: #{toRem(48)};
  padding: var(--b-space-xxs) var(--b-space-l);

  background-color: var(--b-color-tag-accelerator);

  animation: enter 1s ease;
}

.bookingStepperWrapper {
  display: flex;
  justify-content: center;

  border-bottom: 1px solid var(--b-color-divider);
  padding: var(--b-space-m) var(--b-grid-mobile-lateral-gap) var(--b-space-m);

  background-color: var(--b-color-bg-white);

  @media #{$fromLaptop} {
    z-index: var(--b-z-index-2);

    margin-top: unset;
    padding: var(--b-space-m);
  }
}

.bookingStepperMobileWrapper {
  padding: 0 var(--b-space-m);
}

@keyframes enter {
  from {
    transform: translateY(#{toRem(-32)});

    height: 0%;
  }
  to {
    transform: translateY(0);

    height: #{toRem(32)};
  }
}

.alertText {
  width: 100%;

  text-align: center;
}

.exitButton {
  &:hover {
    cursor: pointer;
  }
}
