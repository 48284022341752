@import 'functions';
@import 'mediaQueries';

@mixin touch-security-zone-always {
  min-width: #{toRem(40)};
  min-height: #{toRem(40)};
}

@mixin touch-security-zone {
  @media #{$onlyMobileAndTablet} {
    @include touch-security-zone-always;
  }
}

.touch-security-zone {
  @include touch-security-zone;
}

.touch-security-zone-always {
  @include touch-security-zone-always;
}
