$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.htmlSanitized {
  b,
  strong {
    font-weight: 700;
  }
  em,
  i {
    font-style: italic;
  }
}
