$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.tagButton {
  margin-bottom: var(--b-space-m);
  border: toRem(1) solid var(--b-color-interactive-primary-active);
  border-radius: var(--b-border-radius-8);
  width: fit-content;
  padding: var(--b-space-xs) var(--b-space-s);

  background-color: var(--b-color-bg-white);
  cursor: pointer;

  @media #{$fromLaptop} {
    margin-bottom: var(--b-space-l);
  }

  &.isSelected {
    color: var(--b-color-text-light);
    background-color: var(--b-color-interactive-primary-active);
  }
}
