$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.navigation {
  cursor: pointer;
}

.disableArrow {
  cursor: unset;
}
