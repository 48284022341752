$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/gridLayout';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  @include fullWidthContainer;
  position: relative;

  overflow-x: auto;
  overflow-y: hidden;

  @media #{$fromLaptop} {
    @include fullWidthContainerLateralPadding;
  }
}

.filters {
  @include lateralScrollGradientOnMobile;
  @include fullWidthContainerLateralPadding;

  display: flex;
  gap: var(--b-space-m);
  // grid-column: 1 / -1;

  overflow-x: auto;

  @media #{$onlyMobileAndTablet} {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media #{$fromLaptop} {
    padding-bottom: var(--b-space-xs);
    padding-left: unset;
    padding-right: unset;

    &::-webkit-scrollbar {
      height: var(--b-space-xs);
    }

    &::-webkit-scrollbar-track {
      border-radius: var(--b-space-xs);

      background: var(--b-color-fill-ocean-10);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--b-space-xs);

      background: var(--b-color-interactive-secondary-enabled);
    }
  }
}
