$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  position: fixed;
  z-index: var(--b-z-index-4);
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background-color: var(--b-color-bg-white);
}

.listElement {
  position: relative;

  width: 100%;
  min-height: toRem(73);
  padding: var(--b-space-s) var(--b-space-l) var(--b-space-s) var(--b-space-xl);

  text-align: initial;

  [dir='rtl'] & {
    padding: var(--b-space-s) var(--b-space-xl) var(--b-space-s) 0;
  }

  &:hover {
    background-color: var(--b-color-fill-ocean-10);
    cursor: pointer;
  }
}

.roomName {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkIcon {
  position: absolute;

  // Alinea el icono con la primera frase de texto añadiendo un offset
  top: calc(var(--b-space-s) + #{toRem(4)});
  left: var(--b-space-xs);

  [dir='rtl'] & {
    right: var(--b-space-xs);
    left: unset;
  }

  &.isCenter {
    top: calc(var(--b-space-s) + #{toRem(15)});
  }
}

.header {
  padding: var(--b-space-m) var(--b-space-xxs) var(--b-space-m) var(--b-space-m);

  [dir='rtl'] & {
    padding: var(--b-space-m) var(--b-space-m) var(--b-space-m)
      var(--b-space-xxs);
  }
}

.itemsWrapper {
  flex-grow: 1;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
