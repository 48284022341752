$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mixins/images';
@import 'src/ui/styles/tools/mediaQueries';


.container {
  overflow: hidden;

  box-shadow: var(--b-shadow-mid);
  border-radius: var(--b-space-m);

  @media #{$fromTablet} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  @media #{$onlyDesktop} {
    box-shadow: var(--b-shadow-hard);
    width: unset;
  }
}

.contentWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media #{$onlyTablet} {
    flex-direction: row;
  }

  @media #{$onlyLaptop} {
    flex-direction: row;
  }
}

.background {
  background-size: cover;
  background-position: bottom right;

  &.notFound {
    @include bestQualityBackgroundImageCropped('General', 490, 1000, '30,30,1050,530');

    @media #{$onlyMobile}{
      padding-bottom: var(--b-space-4xl)
    }
  }

  &.essential {
    @include bestQualityBackgroundImageCropped('Essential-1', 490, 1000, '30,30,1050,530');
    @media #{$onlyDesktop}{
      background-position: bottom right;
    }
  }

  &.special {
    @include bestQualityBackgroundImageCropped('Special', 490, 1000, '30,30,1050,530');
  }

  &.unique {
    @include bestQualityBackgroundImageCropped('Unique', 490, 1000, '30,30,1050,530');
  }

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}



.titleWrapper {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;
  align-items: flex-start;

  padding: var(--b-space-l);

  @media #{$onlyMobile} {
    padding-bottom: var(--b-space-xl)
  }

  @media #{$fromTablet} {
    gap: var(--b-space-l);

    padding:var(--b-space-xl) var(--b-space-l) var(--b-space-4xl) var(--b-space-l)
  }

  @media #{$fromLaptop} {
    gap: var(--b-space-xl);

    padding: var(--b-space-xl) var(--b-space-l);
  }

  @media #{$onlyDesktop}{
    gap: var(--b-space-l);
  }

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}

.title {
  position: relative;

  margin-left: auto;
  margin-right: auto;

  @media #{$onlyDesktop}{
    padding-bottom: var(--b-space-3xl);
  }
}
