$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.item {
  display: flex;
  gap: var(--b-space-s);
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 50%;
  width: #{toRem(24)};
  height: #{toRem(24)};
}
