$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.dotWrapper {
  display: flex;
  flex-direction: row;

  &.isSmall {
    gap: toRem(2);
  }

  &.isMedium {
    gap: toRem(8);
  }
}

.dot {
  border-radius: 50%;

  background-color: transparent;

  &.isSmall {
    border: toRem(1) solid var(--b-color-tripadvisor-rating);
    width: var(--b-icon-xs);
    height: var(--b-icon-xs);
  }

  &.isMedium {
    border: toRem(2) solid var(--b-color-tripadvisor-rating);
    width: var(--b-icon-s);
    height: var(--b-icon-s);
  }
}

.complete {
  background-color: var(--b-color-tripadvisor-rating);
}

.half {
  background-image: linear-gradient(
    90deg,
    var(--b-color-tripadvisor-rating) 50%,
    transparent 50%
  );

  [dir='rtl'] & {
    background-image: linear-gradient(
      90deg,
      transparent 50%,
      var(--b-color-tripadvisor-rating) 50%
    );
  }
}
