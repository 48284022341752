$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';

.submitButtonWrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  border-top: #{toRem(1)} solid var(--b-color-fill-neutral-02);
  padding: var(--b-space-xl) var(--b-space-m);

  background-color: var(--b-color-bg-white);

  @media #{$fromTablet} {
    @include stickyButtonsWrapper;

    border-top: unset;
    padding: var(--b-space-xl) var(--b-space-m);

    text-align: center;
  }
}
