@import 'src/ui/styles/tools/mediaQueries';

@mixin contentContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--b-grid-max-container);
  padding-left: var(--b-grid-mobile-lateral-gap);
  padding-right: var(--b-grid-mobile-lateral-gap);

  @media #{$onlyTablet} {
    padding-left: var(--b-grid-tablet-lateral-gap);
    padding-right: var(--b-grid-tablet-lateral-gap);
  }

  @media #{$onlyLaptop} {
    padding-left: var(--b-grid-laptop-lateral-gap);
    padding-right: var(--b-grid-laptop-lateral-gap);
  }

  @media #{$onlyDesktop} {
    padding-left: var(--b-grid-desktop-lateral-gap);
    padding-right: var(--b-grid-desktop-lateral-gap);
  }
}

@mixin fullWidthContainer {
  margin-left: calc(var(--b-grid-mobile-lateral-gap) * -1);
  margin-right: calc(var(--b-grid-mobile-lateral-gap) * -1);

  @media #{$fromTablet} {
    margin-left: calc(var(--b-grid-tablet-lateral-gap) * -1);
    margin-right: calc(var(--b-grid-tablet-lateral-gap) * -1);
  }

  @media #{$fromLaptop} {
    margin-left: calc(var(--b-grid-laptop-lateral-gap) * -1);
    margin-right: calc(var(--b-grid-laptop-lateral-gap) * -1);
  }

  @media #{$onlyDesktop} {
    margin-left: calc(var(--b-grid-full-container-desktop-lateral-gap) * -1);
    margin-right: calc(var(--b-grid-full-container-desktop-lateral-gap) * -1);
  }
}

@mixin fullWidthContainerLateralPadding {
  padding-left: var(--b-grid-mobile-lateral-gap);
  padding-right: var(--b-grid-mobile-lateral-gap);

  @media #{$fromTablet} {
    padding-left: var(--b-grid-tablet-lateral-gap);
    padding-right: var(--b-grid-tablet-lateral-gap);
  }

  @media #{$fromLaptop} {
    padding-left: var(--b-grid-laptop-lateral-gap);
    padding-right: var(--b-grid-laptop-lateral-gap);
  }

  @media #{$onlyDesktop} {
    padding-left: var(--b-grid-full-container-desktop-lateral-gap);
    padding-right: var(--b-grid-full-container-desktop-lateral-gap);
  }
}

@mixin fullHeightContainer {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

@mixin highlightedContainer {
  display: flex;
  gap: var(--b-space-s);
  justify-content: space-between;

  border-radius: var(--b-border-radius-4);
  width: 100%;
  padding: var(--b-space-xxs) var(--b-space-xs);

  background-color: var(--b-color-bg-light);
}
