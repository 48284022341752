$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/gridLayout';
@import 'src/ui/styles/tools/mixins/common';

.container {
  @include fullWidthContainer;
  position: relative;

  @media #{$fromLaptop} {
    @include fullWidthContainerLateralPadding;
  }
}

.mealplanTagContainer {
  @include fullWidthContainerLateralPadding;

  display: flex;
  gap: var(--b-space-m);

  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media #{$fromLaptop} {
    padding-left: unset;
    padding-right: unset;
  }
}

.mealplanTag {
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: #{toRem(1)} solid var(--b-color-interactive-primary-enabled);
  border-radius: #{toRem(8)};
  padding: #{toRem(8)} #{toRem(12)};

  background-color: var(--b-color-interactive-primary-active);

  white-space: nowrap;

  user-select: none;
}
