@import 'src/ui/styles/tools/functions';

$font-size-xxs: toRem(10);
$font-size-xs: toRem(12);
$font-size-s: toRem(14);
$font-size-m: toRem(16);
$font-size-ml: toRem(18);
$font-size-l: toRem(20);
$font-size-xl: toRem(24);
$font-size-2xl: toRem(28);
$font-size-3xl: toRem(36);
$font-size-4xl: toRem(48);
$font-size-5xl: toRem(60);


$mybarcelo-font-size-xl: toRem(28);
$mybarcelo-font-size-2xl: toRem(40);
$mybarcelo-font-size-3xl: toRem(48);
$mybarcelo-font-size-4xl: toRem(72);
$mybarcelo-font-size-5xl: toRem(80);
$mybarcelo-font-size-6xl: toRem(108);


$mybarcelo-line-height-xl: toRem(32);
$mybarcelo-line-height-2xl: toRem(42);
$mybarcelo-line-height-3xl: toRem(48);
$mybarcelo-line-height-4xl: toRem(72);
$mybarcelo-line-height-5xl: toRem(80);
$mybarcelo-line-height-6xl: toRem(118);

$line-height-xs: toRem(16);
$line-height-s: toRem(20);
$line-height-m: toRem(24);
$line-height-l: toRem(30);
$line-height-xl: toRem(34);
$line-height-2xl: toRem(36);
$line-height-3xl: toRem(42);
$line-height-4xl: toRem(56);
$line-height-5xl: toRem(70);

$font-weight-300: 300;
$font-weight-500: 500;
$font-weight-700: 700;

$mybarcelo-font-weight-300: 300;
$mybarcelo-font-weight-500: 500;
$mybarcelo-font-weight-700: 700;

$letter-spacing-01: 0em;
$letter-spacing-02: 0.0015em;
$letter-spacing-03: 0.0025em;
$letter-spacing-04: 0.004em;
$letter-spacing-05: 0.005em;
$letter-spacing-06: 0.015em;

$mybarcelo-letter-spacing-xl: toRem(0);
$mybarcelo-letter-spacing-2xl: toRem(-0.8);
$mybarcelo-letter-spacing-3xl: toRem(-0.96);
$mybarcelo-letter-spacing-4xl: toRem(-1.44);
$mybarcelo-letter-spacing-5xl: toRem(0.4);
$mybarcelo-letter-spacing-6xl: toRem(0.54);

/** XXS - Solo para las tags **/

@mixin font-xxs-500 {
  font-size: #{$font-size-xxs};
  line-height: #{$line-height-xs};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-04};
}

/** XS **/

@mixin font-xs-300 {
  font-size: #{$font-size-xs};
  line-height: #{$line-height-xs};
  font-weight: #{$font-weight-300};
  letter-spacing: #{$letter-spacing-04};
}

@mixin font-xs-500 {
  font-size: #{$font-size-xs};
  line-height: #{$line-height-xs};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-06};
}

@mixin font-xs-700 {
  font-size: #{$font-size-xs};
  line-height: #{$line-height-xs};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-06};
}

/** S **/

@mixin font-s-300 {
  font-size: #{$font-size-s};
  line-height: #{$line-height-s};
  font-weight: #{$font-weight-300};
  letter-spacing: #{$letter-spacing-03};
}

@mixin font-s-500 {
  font-size: #{$font-size-s};
  line-height: #{$line-height-s};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-03};
}

@mixin font-s-700 {
  font-size: #{$font-size-s};
  line-height: #{$line-height-s};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-03};
}

@mixin font-s-link {
  font-size: #{$font-size-s};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-03};
}

/** M **/

@mixin font-m-300 {
  font-size: #{$font-size-m};
  line-height: #{$line-height-m};
  font-weight: #{$font-weight-300};
  letter-spacing: #{$letter-spacing-05};
}

@mixin font-m-500 {
  font-size: #{$font-size-m};
  line-height: #{$line-height-m};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-05};
}

@mixin font-m-700 {
  font-size: #{$font-size-m};
  line-height: #{$line-height-m};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-05};
}

@mixin font-link {
  font-size: #{$font-size-m};
  line-height: #{$line-height-m};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-05};
}

@mixin font-button {
  font-size: #{$font-size-m};
  line-height: #{$line-height-l};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-05};
}

/** M-L **/

@mixin font-ml-300 {
  font-size: #{$font-size-ml};
  line-height: #{$line-height-m};
  font-weight: #{$font-weight-300};
  letter-spacing: #{$letter-spacing-02};
}

@mixin font-ml-500 {
  font-size: #{$font-size-ml};
  line-height: #{$line-height-m};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-02};
}

@mixin font-ml-700 {
  font-size: #{$font-size-ml};
  line-height: #{$line-height-m};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-02};
}

/** L **/

@mixin font-l-300 {
  font-size: #{$font-size-l};
  line-height: #{$line-height-l};
  font-weight: #{$font-weight-300};
  letter-spacing: #{$letter-spacing-02};
}

@mixin font-l-500 {
  font-size: #{$font-size-l};
  line-height: #{$line-height-l};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-02};
}

@mixin font-l-700 {
  font-size: #{$font-size-l};
  line-height: #{$line-height-l};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-02};
}

/** XL **/

@mixin font-xl-300 {
  font-size: #{$font-size-xl};
  line-height: #{$line-height-xl};
  font-weight: #{$font-weight-300};
  letter-spacing: #{$letter-spacing-01};
}

@mixin font-xl-500 {
  font-size: #{$font-size-xl};
  line-height: #{$line-height-xl};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-01};
}


@mixin font-xl-700 {
  font-size: #{$font-size-xl};
  line-height: #{$line-height-xl};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-01};
}

/** 2XL **/

@mixin font-2xl-500 {
  font-size: #{$font-size-2xl};
  line-height: #{$line-height-2xl};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-02};
}

@mixin font-2xl-700 {
  font-size: #{$font-size-2xl};
  line-height: #{$line-height-2xl};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-02};
}

/** 3XL **/

@mixin font-3xl-700 {
  font-size: #{$font-size-3xl};
  line-height: #{$line-height-3xl};
  font-weight: #{$font-weight-700};
  letter-spacing: #{$letter-spacing-03};
}

/** 4XL **/

@mixin font-4xl-500 {
  font-size: #{$font-size-4xl};
  line-height: #{$line-height-4xl};
  font-weight: #{$font-weight-500};
  letter-spacing: #{$letter-spacing-01};
}


/** MyBarcelo **/

/** XL **/

@mixin mybarcelo-font-xl-500 {
  font-size: #{$mybarcelo-font-size-xl};
  line-height: #{$mybarcelo-line-height-xl};
  font-weight: #{$mybarcelo-font-weight-500};
  letter-spacing: #{$mybarcelo-letter-spacing-xl};
}

@mixin mybarcelo-font-xl-700 {
  font-size: #{$mybarcelo-font-size-xl};
  line-height: #{$mybarcelo-line-height-xl};
  font-weight: #{$mybarcelo-font-weight-700};
  letter-spacing: #{$mybarcelo-letter-spacing-xl};
}

/** 2XL **/

@mixin mybarcelo-font-2xl-500 {
  font-size: #{$mybarcelo-font-size-2xl};
  line-height: #{$mybarcelo-line-height-2xl};
  font-weight: #{$mybarcelo-font-weight-500};
  letter-spacing: #{$mybarcelo-letter-spacing-2xl};
}

@mixin mybarcelo-font-2xl-700 {
  font-size: #{$mybarcelo-font-size-2xl};
  line-height: #{$mybarcelo-line-height-2xl};
  font-weight: #{$mybarcelo-font-weight-700};
  letter-spacing: #{$mybarcelo-letter-spacing-2xl};
}

/** 3XL **/

@mixin mybarcelo-font-3xl-500 {
  font-size: #{$mybarcelo-font-size-3xl};
  line-height: #{$mybarcelo-line-height-3xl};
  font-weight: #{$mybarcelo-font-weight-500};
  letter-spacing: #{$mybarcelo-letter-spacing-3xl};
}

@mixin mybarcelo-font-3xl-700 {
  font-size: #{$mybarcelo-font-size-3xl};
  line-height: #{$mybarcelo-line-height-3xl};
  font-weight: #{$mybarcelo-font-weight-700};
  letter-spacing: #{$mybarcelo-letter-spacing-3xl};
}

/** 4XL **/

@mixin mybarcelo-font-4xl-700 {
  font-size: #{$mybarcelo-font-size-4xl};
  line-height: #{$mybarcelo-line-height-4xl};
  font-weight: #{$mybarcelo-font-weight-700};
  letter-spacing: #{$mybarcelo-letter-spacing-4xl};
}

@mixin mybarcelo-font-4xl-500 {
  font-size: #{$mybarcelo-font-size-4xl};
  line-height: #{$mybarcelo-line-height-4xl};
  font-weight: #{$mybarcelo-font-weight-500};
  letter-spacing: #{$mybarcelo-letter-spacing-4xl};
}

/** 5XL **/

@mixin mybarcelo-font-5xl-500 {
  font-size: #{$mybarcelo-font-size-5xl};
  line-height: #{$mybarcelo-line-height-5xl};
  font-weight: #{$mybarcelo-font-weight-500};
  letter-spacing: #{$mybarcelo-letter-spacing-5xl};
}

/** 6XL **/

@mixin mybarcelo-font-6xl-500 {
  font-size: #{$mybarcelo-font-size-6xl};
  line-height: #{$mybarcelo-line-height-6xl};
  font-weight: #{$mybarcelo-font-weight-500};
  letter-spacing: #{$mybarcelo-letter-spacing-6xl};
}
