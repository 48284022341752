@import 'src/ui/styles/tools/mediaQueries';

@mixin gridLayout {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--b-grid-mobile-gutter-gap);

  @media #{$fromLaptop} {
    grid-template-columns: repeat(12, 1fr);
  }

  @media #{$onlyDesktop} {
    column-gap: var(--b-grid-desktop-gutter-gap);
  }
}
