$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';

.container {
  box-shadow: var(--b-shadow-inverted);
  border-top: #{toRem(1)} solid var(--b-color-fill-neutral-02);
  padding: var(--b-space-m) 0;

  background-color: var(--b-color-icon-light);

  @media #{$fromLaptop} {
    @include fullWidthContainerLateralPadding;
    border-radius: unset;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  &.allRoomsSelected {
    display: grid;
    grid-template-rows: 1fr auto;
    @media #{$fromLaptop} {
      grid-template-columns: 70% 30%;
    }
  }
}

.roomsContainer{
  @media #{$onlyMobileAndTablet} {
    overflow: auto;
  }
}

.reserveWrapper {
  padding: var(--b-space-m) var(--b-space-m) 0 var(--b-space-m);

  @media #{$fromLaptop} {
    padding: unset;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;

  @media #{$onlyTablet} {
    gap: var(--b-space-m);
    flex-direction: row;
    align-items: flex-start;
  }
}

.price {
  display: flex;

  text-align: end;

  @media #{$fromLaptop} {
    flex-direction: column;
  }
}

.staticContainer {
  display: flex;
  gap: var(--b-space-m);

  margin: var(--b-space-m) var(--b-space-m) 0 var(--b-space-m);
  min-width: 0;
}

.scrollContainer {
  overflow: auto;

  margin-top: var(--b-space-m);

  white-space: nowrap;

  @media #{$fromLaptop} {
    padding-bottom: var(--b-space-xs);
    &::-webkit-scrollbar {
      height: var(--b-space-xs);
    }

    &::-webkit-scrollbar-track {
      border-radius: var(--b-space-xs);

      background: var(--b-color-fill-ocean-10);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--b-space-xs);

      background: var(--b-color-interactive-secondary-enabled);
    }
  }
}

.scrollWrapper {
  --gradient-width: #{toRem(16)};

  position: relative;

  width: 100vw;

  @media #{$fromLaptop} {
    margin-left: calc(var(--b-space-m) * -1);
    width: 100%;
  }
}

.scrollWrapper::before {
  @media #{$fromLaptop} {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    transform: rotate(-180deg);

    width: var(--gradient-width);
    height: 100%;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 6.64%,
      #ffffff 100%
    );
  }
}

.scrollWrapper::after {
  @media #{$fromLaptop} {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;

    width: var(--gradient-width);
    height: 100%;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 6.64%,
      #ffffff 100%
    );
  }
}

.firstButton {
  margin-left: var(--b-space-m);

  [dir='rtl'] & {
    margin-right: var(--b-space-m);
  }
}

.ellipsisText {
  overflow: hidden;

  margin: 0 var(--b-space-m);

  white-space: nowrap;
  text-overflow: ellipsis;

  @media #{$fromLaptop} {
    margin-left: unset;
    margin-right: unset;
  }
}
