$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/gridLayout';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.darkCorner {
  margin-top: var(--b-space-3xl);

  @media #{$fromLaptop} {
    border-bottom-left-radius: #{toRem(16)};
    border-top-right-radius: #{toRem(16)};
    border-bottom-right-radius: #{toRem(16)};

    background-color: var(--b-color-interactive-primary-enabled);
  }
}

.container {
  @include gridLayout;
  position: relative;

  box-shadow: var(--b-shadow-hard);

  background-color: var(--b-color-bg-white);

  &.isSelected {
    // pinta el borde por encima de la card para cubrir márgenes negativos
    &:before {
      content: '';
      position: absolute;
      z-index: var(--b-z-index-3);
      top: calc(#{toRem(1)} * -1);

      margin-left: calc(var(--b-grid-mobile-lateral-gap) * -1);
      margin-right: calc(var(--b-grid-mobile-lateral-gap) * -1);
      border: #{toRem(2)} solid var(--b-color-interactive-primary-enabled);
      width: calc(100% + var(--b-grid-mobile-lateral-gap) * 2);
      height: #{toRem(2)};

      background-color: transparent;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: var(--b-z-index-3);
      bottom: calc(#{toRem(1)} * -1);

      margin-left: calc(var(--b-grid-mobile-lateral-gap) * -1);
      margin-right: calc(var(--b-grid-mobile-lateral-gap) * -1);
      border: #{toRem(2)} solid var(--b-color-interactive-primary-enabled);
      width: calc(100% + var(--b-grid-mobile-lateral-gap) * 2);
      height: #{toRem(2)};

      background-color: transparent;
    }

    @media #{$fromTablet} {
      &:before {
        margin-left: calc(var(--b-grid-tablet-lateral-gap) * -1);
        margin-right: calc(var(--b-grid-tablet-lateral-gap) * -1);
        width: calc(100% + var(--b-grid-tablet-lateral-gap) * 2);
      }
      &:after {
        margin-left: calc(var(--b-grid-tablet-lateral-gap) * -1);
        margin-right: calc(var(--b-grid-tablet-lateral-gap) * -1);
        width: calc(100% + var(--b-grid-tablet-lateral-gap) * 2);
      }
    }

    @media #{$fromLaptop} {
      border: #{toRem(4)} solid var(--b-color-interactive-primary-enabled);

      &:before {
        content: unset;
      }
      &:after {
        content: unset;
      }
    }
  }

  @media #{$fromLaptop} {
    row-gap: var(--b-space-l);

    border: #{toRem(1)} solid var(--b-color-fill-neutral-02);
    border-radius: #{toRem(16)};
    padding: var(--b-space-xl);
  }
}

.info {
  @include fullWidthContainer;
  grid-column: 1 / -1;

  background-color: var(--b-color-bg-white);

  @media #{$fromLaptop} {
    margin: unset;
  }
}

.selected {
  @include font-m-700;
  position: absolute;
  top: calc(var(--b-space-3xl) * -1);
  left: calc(#{toRem(2)} * -1);

  display: block;

  border-radius: var(--b-space-m) var(--b-space-m) 0 0;
  padding: var(--b-space-s) var(--b-space-l);

  background: var(--b-color-interactive-primary-enabled);
  color: var(--b-color-text-light);

  [dir='rtl'] & {
    right: calc(#{toRem(2)} * -1);
    left: unset;
  }

  @media #{$fromLaptop} {
    margin-left: calc(#{toRem(2)} * -1);
    margin-right: calc(#{toRem(2)} * -1);
  }
}

.rateSelectorContainer {
  @include fullWidthContainer;
  @include fullWidthContainerLateralPadding;

  display: flex;
  gap: var(--b-space-l);
  flex-direction: column;
  grid-column: 1 / -1;

  background-color: var(--b-color-bg-white);

  @media #{$fromLaptop} {
    margin-left: unset;
    margin-right: unset;
    padding-left: unset;
    padding-right: unset;
  }
}
