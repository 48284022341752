$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.filter {
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: #{toRem(1)} solid var(--b-color-interactive-primary-enabled);
  border-radius: #{toRem(8)};
  padding: #{toRem(8)} #{toRem(12)};

  background-color: unset;

  white-space: nowrap;

  @media #{$fromLaptop} {
    &:hover {
      background-color: var(--b-color-interactive-primary-hover);
      cursor: pointer;
    }
    &:active {
      background-color: var(--b-color-interactive-primary-pressed);
      cursor: pointer;
    }
  }
}

.filterActive {
  background-color: var(--b-color-interactive-primary-active);

  &:hover {
    background-color: var(--b-color-interactive-primary-active);
  }
}
