@mixin bestQualityBackgroundImage($imageName, $width, $maxWidth, $format: 'png8-alpha', $qlt: 60, $brightness: 0) {
  $width2x: if($width * 2 > $maxWidth, $maxWidth, $width * 2);
  $width3x: if($width * 3 > $maxWidth, $maxWidth, $width * 3);

    background-image: url($IMAGES_BASE_URL + $imageName + '?fmt=' + $format + '&wid=' + $width + '&qlt=' + $qlt + '&op_brightness=' + $brightness);

    /* stylelint-disable unit-no-unknown */
    /* stylelint-disable scss/operator-no-unspaced */
    background-image: image-set(
      url($IMAGES_BASE_URL + $imageName + '?fmt=' + $format + '&wid=' + $width + '&qlt=' + $qlt + '&op_brightness=' + $brightness)
      1x,
      url($IMAGES_BASE_URL + $imageName + '?fmt=' + $format + '&wid=' + $width2x + '&qlt=' + $qlt + '&op_brightness=' + $brightness)
      2x,
      url($IMAGES_BASE_URL + $imageName + '?fmt=' + $format + '&wid=' + $width3x + '&qlt=' + $qlt + '&op_brightness=' + $brightness)
      3x
      );
      /* stylelint-enable unit-no-unknown */
      /* stylelint-enable scss/operator-no-unspaced */

}

@mixin bestQualityBackgroundImageCropped($imageName, $width, $maxWidth, $cropValues, $qlt: 100) {
  $width2x: if($width * 2 > $maxWidth, $maxWidth, $width * 2);
  $width3x: if($width * 3 > $maxWidth, $maxWidth, $width * 3);

  background-image: url($IMAGES_BASE_URL + $imageName + '?fmt=png-alpha&wid=' + $width + '&qlt=' + $qlt + '&crop=' + $cropValues);

  /* stylelint-disable unit-no-unknown */
  /* stylelint-disable scss/operator-no-unspaced */
  background-image: image-set(
    url($IMAGES_BASE_URL + $imageName + '?fmt=png-alpha&wid=' + $width + '&qlt=100&crop=' + $cropValues) 1x,
    url($IMAGES_BASE_URL + $imageName + '?fmt=png-alpha&wid=' + $width2x + '&qlt=100&crop=' + $cropValues) 2x,
    url($IMAGES_BASE_URL + $imageName + '?fmt=png-alpha&wid=' + $width3x + '&qlt=100&crop=' + $cropValues) 3x
  );
  /* stylelint-enable unit-no-unknown */
  /* stylelint-enable scss/operator-no-unspaced */



}
