$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mediaQueries';

.card {
  position: relative;
  z-index: var(--b-z-index-1);

  display: flex;
  flex-direction: column;

  padding: var(--b-space-l);

  background-color: var(--b-color-bg-white);

  @media #{$onlyLaptop} {
    //Ancho defindo por el sistema de diseño
    min-width: toRem(360);
  }

  @media #{$onlyTablet} {
    min-width: toRem(288);
    padding: var(--b-space-l) var(--b-space-m);
  }

}

.list {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;
  align-items: flex-start;

  height: 100%;
 }

 .benefitsButton {
   margin-top: var(--b-space-l);

   @media #{$onlyTablet} {
     margin-top: var(--b-space-3xl);
     min-height: toRem(56);
   }
 }
