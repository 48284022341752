$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mixins/common';

.tagsWrapper {
  @include font-xxs-500;
  position: absolute;
  top: calc(var(--b-space-m) * -1);
  right: var(--b-space-xs);
  left: var(--b-space-xs);

  display: flex;
  gap: var(--b-space-xs);

  text-transform: uppercase;

  [dir='rtl'] & {
    right: var(--b-space-xs);
    left: unset;
  }
}

.bestPriceTag {
  background-color: var(--b-color-support-warning);
}

.infoButton {
  position: absolute;

  // Ajuste manual para que el button se vea alineado con el texto
  top: #{toRem(18)};
  right: #{toRem(12)};

  display: flex;
  justify-content: center;
  align-items: center;

  [dir='rtl'] & {
    right: unset;
    left: var(--b-space-xs);
  }
}

.myBarceloLogo {
  vertical-align: middle;
}
