$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/touch';

.container {
  display: Flex;
  overflow: hidden;

  box-shadow: var(--b-shadow-soft);
  border-radius: var(--b-border-radius-16);

  background-color: var(--b-color-fill-neutral-00);
}

.dataWrapper {
  display: flex;
  gap: var(--b-space-l);
  flex-direction: column;
  flex-grow: 1;

  padding: var(--b-space-m) var(--b-space-xl);
}

.verticalDivider {
  align-self: stretch;

  margin: 0 var(--b-space-xl);
  border-left: 1px solid var(--b-color-divider);
  width: 0;
}

.heroLineDataWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 100%;
}

.heroLineData {
  display: inline-flex;
  align-items: center;
  flex-grow: 1;

  margin-right: var(--b-space-xs);
  max-width: 100%;
  min-height: toRem(40);
}

.heroLineEllipsisText {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.couponLink {
  @include interactiveUnderline;
  cursor: pointer;
}

.editButton {
  @include touch-security-zone-always;
}

.datesRange {
  text-transform: capitalize;
}
