@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

@mixin textField {
  overflow: scroll;

  box-shadow: 0 0 0 #{toRem(1)} var(--b-color-field-enabled) inset;
  border: none;
  border-radius: #{toRem(8)};
  height: #{toRem(40)};
  padding: 0 var(--b-space-2xl) 0 var(--b-space-s);

  outline: none;

  /* Esto es necesario para que IOS tenga en cuenta la propiedad box-shadow*/
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;

  background-color: var(--b-color-bg-white);

  @include font-m-300;

  [dir='rtl'] & {
    padding: 0 var(--b-space-s) 0 var(--b-space-2xl);
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 #{toRem(2)} var(--b-color-field-active) inset;
  }

  &:disabled {
    box-shadow: 0 0 0 #{toRem(1)} var(--b-color-field-disabled) inset;

    color: var(--b-color-text-disabled);
    -webkit-text-fill-color: var(--b-color-text-disabled);
  }

  &:hover:not(:focus):not(:active):not(:disabled) {
    box-shadow: 0 0 0 #{toRem(2)} var(--b-color-field-hover) inset;
  }
}
