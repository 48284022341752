$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/images';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  padding: var(--b-space-xl) var(--b-space-m);

  @media #{$fromTablet} {
    padding: var(--b-space-xl);
  }

  background-position: center center;
  background-size: cover;

  &.notFound {
    @include bestQualityBackgroundImageCropped('GeneralS', 716, 716, '0,0,716,220');
  }

  &.essential {
    @include bestQualityBackgroundImageCropped('Essential-1', 490, 1000, '30,30,1050,530');
    background-position: top right;
  }

  &.special {
    @include bestQualityBackgroundImageCropped('Special', 490, 1000, '30,30,1050,530');
  }

  &.unique {
    @include bestQualityBackgroundImage('fondoUnique', 600, 600, 'png8-alpha', 60, -100);

    @media #{$fromTablet} {
      @include bestQualityBackgroundImage('fondoUnique', 800, 800, 'png8-alpha', 60, -100);
    }
  }

}

