$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.container {
  display: flex;
  align-items: center;

  border-radius: toRem(24);
  padding: var(--b-space-xxs) var(--b-space-xs) var(--b-space-xxs) var(--b-space-xxs);

  cursor: pointer;
  background-color: var(--b-color-button-secondary-hover);
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--b-color-button-inverse-hover);
  }

  [dir='rtl'] & {
    padding: var(--b-space-xxs) var(--b-space-xxs) var(--b-space-xxs) var(--b-space-xs);
  }
}
