$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mixins/images';

.container {
  overflow: hidden;

  box-shadow: var(--b-shadow-hard);
  border-radius: var(--b-space-m);

  @media #{$fromTablet} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  @media #{$onlyDesktop} {
    width: unset;
  }
}

.contentWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media #{$onlyLaptop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media #{$onlyDesktop} {
    display: flex;
    gap: unset;
    flex-direction: column;
  }
}

.titleWrapper {
  @include bestQualityBackgroundImage('banner-mybarcelo-background', 500, 500, 'webp');
  display: flex;
  justify-content: center;
  align-items: center;

  padding: var(--b-space-xl);

  background-size: cover;
  background-position: center;

  @media #{$fromTablet} {
    padding: var(--b-space-l);
  }

  // Altura mínima para versión alargada
  @media #{$onlyDesktop} and (min-height: 940px) {
    padding: var(--b-space-l) var(--b-space-xs);
  }

  @media #{$onlyDesktop} and (max-height: 939.98px) {
    display: none;
  }
}

.title {
  position: relative;

  margin-left: auto;
  margin-right: auto;

  text-align: center;

  @media #{$onlyLaptop} {
    padding: var(--b-space-3xl);
  }
}
