$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/common';

.mealplansInfoButton {
  cursor: pointer;
  stroke: var(--b-color-button-secondary-enabled);

  &:hover {
    stroke: var(--b-color-interactive-secondary-hover);
  }

  &:active {
    stroke: var(--b-color-interactive-secondary-pressed);
  }
}
