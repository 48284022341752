$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/common';

.container {
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;

  @media #{$fromLaptop} {
    position: relative;
    z-index: unset;

    margin: unset;
    border: unset;
    padding: unset;

    background-color: var(--b-color-bg-white);
  }
}

.containerInterior {
  @include fullWidthContainer;
  padding-top: var(--b-space-l);
  padding-bottom: var(--b-space-m);

  background-color: var(--b-color-bg-white);

  @media #{$onlyMobile} {
    padding-bottom: var(--b-space-l);
    padding-left: var(--b-grid-mobile-lateral-gap);
    padding-right: var(--b-grid-mobile-lateral-gap);
  }

  @media #{$onlyTablet} {
    height: 100%;
    padding-bottom: var(--b-space-l);
    padding-left: var(--b-grid-tablet-lateral-gap);
    padding-right: var(--b-grid-tablet-lateral-gap);
  }

  @media #{$fromLaptop} {
    margin: unset;
    padding: unset;

    background-color: unset;
  }
}

.room {
  text-transform: uppercase;
}

.roomSummary {
  display: flex;
  flex-direction: column-reverse;

  @media #{$fromLaptop} {
    gap: var(--b-space-l);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.divider {
  @media #{$fromLaptop} {
    width: 1px;
    height: var(--b-space-l);

    background-color: var(--b-color-fill-neutral-03);
  }
}

.capacity {
  display: flex;
  gap: var(--b-space-xxs);
  align-items: center;

  margin-top: var(--b-space-xxs);

  @media #{$fromLaptop} {
    margin-top: unset;
  }
}
