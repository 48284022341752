$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

// Ancho de las cards para móvil definido en FIGMA
$card-width: toRem(264);

.container {
  padding: var(--b-space-3xl) var(--b-space-l) var(--b-space-l);

  background-color: var(--b-color-fill-pool-10);

}

.advantagesContainer {
  display: flex;
  gap: var(--b-space-xl);
  flex-direction: row;

  width: calc(100% + var(--b-space-l));
  padding-bottom: var(--b-space-l);

  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  @media #{$onlyMobile} {
    margin-bottom: var(--b-space-l);
  }

  @media #{$fromTablet}{
    flex-direction: column;

    width: 100%;

  }
}

.advantagesCard {
  min-width: $card-width;

  scroll-snap-align: start;
}

.lastCard {
  @media #{$onlyMobile} {
    margin-right: $card-width;
  }
}

.lastCardRtl {
  @media #{$onlyMobile} {
    margin-left: $card-width;
  }
}
