$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/common';

.container {
  display: flex;
  gap: var(--b-space-l);
  flex-direction: column;

  @media #{$fromLaptop} {
    flex-direction: row;
    align-items: flex-end;
  }
}
.selectContainer {
  position: relative;

  flex-grow: 1;

  cursor: pointer;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: var(--b-space-xxs);
}

.button {
  display: flex;

  border-radius: #{toRem(8)};
  width: 100%;
  padding: var(--b-space-xs) var(--b-space-s);

  color: var(--b-color-text-dark);

  vertical-align: middle;
  background-color: var(--b-color-bg-white);
  outline: #{toRem(1)} solid var(--b-color-interactive-primary-enabled);

  &:hover,
  .isOpen > & {
    cursor: pointer;
    outline: #{toRem(2)} solid var(--b-color-interactive-primary-enabled);
  }
}

.clearFilter {
  @include genericLink;

  flex-shrink: 0;

  color: var(--b-color-text-dark);

  &.isHidden {
    display: none;

    @media #{$fromLaptop} {
      display: block;

      visibility: hidden;
    }
  }
}

.buttonText {
  @include font-m-300;

  flex: 1;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
