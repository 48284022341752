$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/common';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  text-align: center;
}

.roomNames {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: column;
  align-items: center;

  margin-bottom: var(--b-space-m);

  text-align: center;
}

.buttonsContainer {
  @include stickyButtonsWrapper;

  display: flex;
  gap: var(--b-space-l);
  flex-direction: column;
  justify-content: center;

  padding-top: var(--b-space-xl);

  @media #{$fromLaptop} {
    flex-direction: row;
  }
}
