$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';

.card {
  position: relative;
  z-index: var(--b-z-index-1);

  padding: var(--b-space-l);

  background-color: var(--b-color-bg-white);

  @media #{$onlyLaptop} {
    padding: var(--b-space-xl) var(--b-space-m);
  }

  @media #{$onlyDesktop} {
    padding-left: var(--b-space-m);
    padding-right: var(--b-space-m);
  }
}

.list {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;
  align-items: center;
}
