$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mixins/gridLayout';

.container {
  @media #{$fromLaptop} {
    display: flex;
    gap: var(--b-space-2xl);
    align-items: center;
  }
}

.carouselContainer {
  @include contentContainer;

  flex-grow: 1;

  height: toRem(224);

  @media #{$fromLaptop} {
    flex-grow: 0;
    flex-shrink: 0;

    width: toRem(370);
    height: toRem(270);
    padding: unset;
  }
}

.detailsContainer {
  @include contentContainer;

  display: flex;
  gap: var(--b-space-xs);
  flex-direction: column;
  align-items: flex-start;

  padding-top: var(--b-space-m);
  padding-bottom: var(--b-space-l);

  @media #{$fromLaptop} {
    gap: var(--b-space-m);
    flex-grow: 1;

    padding: unset;
  }
}

.details {
  display: flex;
  gap: var(--b-space-s);

  border-radius: #{toRem(4)};
  padding: #{toRem(4)} #{toRem(8)};

  background-color: var(--b-color-fill-neutral-01);

  @media #{$fromLaptop} {
    width: fit-content;
  }
}

.services {
  display: flex;
  gap: var(--b-space-l);
  flex-wrap: wrap;

  padding-left: 0;
  padding-right: 0;
}

.service {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
  flex-shrink: 0;
}

.serviceImage {
  flex-shrink: 0;

  // hack: busca eliminar el color "azul" de los iconos de forma artifical
  filter: grayscale(100%) brightness(115%);
}

.infoSkeleton {
  border-radius: toRem(16);
}
