$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.dropdown {
  z-index: calc(var(--b-z-index-3) + 1);
  top: var(--b-space-l);
  right: 0;
  left: 0;

  width: 100%;
  padding: unset;

  overflow-y: auto;

  [dir='rtl'] & {
    right: unset;
    left: #{toRem(0)};
  }
}

.container {
  padding: var(--b-space-m) 0;
}

.button {
  position: relative;

  width: 100%;
  padding: var(--b-space-s) var(--b-space-l) var(--b-space-s) var(--b-space-xl);

  text-align: initial;

  [dir='rtl'] & {
    padding: var(--b-space-s) var(--b-space-xl) var(--b-space-s) 0;
  }

  &:hover {
    background-color: var(--b-color-fill-ocean-10);
    cursor: pointer;
  }
}

.roomName {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkIcon {
  position: absolute;

  // Alinea el icono con la primera frase de texto añadiendo un offset
  top: calc(var(--b-space-s) + #{toRem(4)});
  left: var(--b-space-xs);

  [dir='rtl'] & {
    right: var(--b-space-xs);
    left: unset;
  }
}
