$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';

.wrapper {
  display: flex;
  flex-direction: column;

  text-align: right;

  [dir='rtl'] & {
    text-align: left;
  }
}
