$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.carousel {
  overflow: hidden;

  border-radius: unset;

  cursor: default;
}

.details {
  padding: var(--b-space-l) var(--b-space-m);
}

.hotelDetail {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;
}

.highlightTag {
  border-radius: toRem(8);
  width: fit-content;
  padding: var(--b-space-xxs) var(--b-space-xs);

  background-color: var(--b-color-tag-highlight);
}

.servicesContainer {
  display: flex;
  gap: var(--b-space-xs);

  margin-left: calc(var(--b-space-m) * -1);
  margin-right: calc(var(--b-space-m) * -1);

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media #{$onlyMobileAndTablet} {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media #{$fromLaptop} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    margin-left: unset;
    margin-right: unset;
  }
}

.service {
  flex-shrink: 0;

  border: toRem(1) solid var(--b-color-fill-neutral-02);
  border-radius: toRem(8);
  width: toRem(158);
  padding: var(--b-space-m) var(--b-space-xs);

  @media #{$fromLaptop} {
    width: unset;
  }

  &:first-child {
    margin-left: var(--b-space-m);

    @media #{$fromLaptop} {
      margin-left: unset;
    }
  }

  &:last-child {
    margin-right: var(--b-space-m);

    @media #{$fromLaptop} {
      margin-right: unset;
    }
  }
}

.serviceTitle {
  display: -webkit-box;
  overflow: hidden;

  text-align: center;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.divider {
  margin: 0 var(--b-space-l);
  height: auto;
}

.horizontalDivider {
  margin: var(--b-space-l) calc(var(--b-space-m) * -1);
  width: auto;
}

.promotions {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: column;
}

.showMoreButton {
  display: flex;

  outline: unset;
  cursor: pointer;
}

.callCenterPhone {
  display: flex;
  justify-content: center;
  align-items: center;

  border: #{toRem(2)} solid var(--b-color-button-secondary-enabled);
  border-radius: #{toRem(100)};
  max-height: #{toRem(36)};
  min-height: #{toRem(44)};
  padding: var(--b-space-xs) var(--b-space-xl);
}

.icon {
  margin-top: toRem(5);
}
